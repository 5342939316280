import { Position } from './types';

const positionIndexes = [1, 2, 3, 4, 5, 6, 7, 8] as const;

type TravelCallback<T> = (position: Position) => T | undefined;

export const travel = <T>(callback: TravelCallback<T>): T | undefined => {
  for (let v of positionIndexes) {
    for (let h of positionIndexes) {
      const posistion = { h, v };
      const r = callback(posistion);
      if (!!r) {
        return r;
      }
    }
  }
};

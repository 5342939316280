import { useState } from 'react';
import {
  checkWasMoved,
  Move,
  PossibleMoves,
  WasMoved,
  getToPosition,
  equalMove,
} from './possibleMoves';
import { Board, Piece, PieceColor } from './board';
import { generateBoard, BoardType } from './boardGenerator';
import { Position } from './grid';

const initWasMoved = () => ({
  w: { king: false, kingRook: false, queenRook: false },
  b: { king: false, kingRook: false, queenRook: false },
});

export type GameCell = {
  piece?: Piece;
  possibleMoves?: Move[];
};
export interface Game {
  reset(): void;
  read(posistion: Position): GameCell;
  move(move: Move): void;
  isColorTurn(color: PieceColor): boolean;
  previousMove?: Move;
  checkmate: { w: boolean; b: boolean };
}

type GameConfig = {
  randomize?: boolean;
  byTurns?: boolean;
  boardType?: BoardType;
};

const isLegalMove = (possibleMoves: PossibleMoves, move: Move): boolean =>
  !!possibleMoves
    .read(getToPosition(move))
    .incoming.find((m) => equalMove(m, move));

export const useGame = ({
  boardType,
  byTurns = true,
}: GameConfig = {}): Game => {
  const [isWhitesTurn, setWhitesTurn] = useState<boolean>(true);
  const [board, setBoard] = useState(() => new Board(generateBoard(boardType)));
  const [possibleMoves, setPossibleMoves] = useState(
    () => new PossibleMoves(board)
  );
  const [previousMove, setPreviousMove] = useState<Move>();
  const [wasMoved, setWasMoved] = useState<WasMoved>(initWasMoved());

  const reset = () => {
    setBoard(new Board(generateBoard(boardType)));
    setWhitesTurn(true);
    setPreviousMove(undefined);
    setWasMoved(initWasMoved());
  };

  const isColorTurn = (color: PieceColor) =>
    byTurns ? (color === `w` ? isWhitesTurn : !isWhitesTurn) : true;

  const move = (move: Move) => {
    if (!isLegalMove(possibleMoves, move)) {
      return;
    }
    const newWasMoved = checkWasMoved(wasMoved, move);
    board.runOpps(move);
    const newPossibleMoves = new PossibleMoves(board, {
      previousMove: move,
      wasMoved: newWasMoved,
    });

    setWhitesTurn(board.read(getToPosition(move)).piece?.color === 'b');
    setBoard(board);
    setWasMoved(newWasMoved);
    setPreviousMove(move);
    setPossibleMoves(newPossibleMoves);
  };

  const read = (posistion: Position) => {
    const { piece } = board.read(posistion);
    const { outgoing } = possibleMoves.read(posistion);
    // incoming moves must be cloned if are going to be shared outside the game since they are used to verify isLegalMove
    return { piece, possibleMoves: outgoing };
  };

  return {
    reset,
    read,
    move,
    isColorTurn,
    previousMove,
    checkmate: {
      w: possibleMoves.isCheckmate('w'),
      b: possibleMoves.isCheckmate('b'),
    },
  };
};

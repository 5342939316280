import { BoardOpp, PieceColor } from '../board';

const positionIndexes = [1, 2, 3, 4, 5, 6, 7, 8] as const;
const names = [
  ['r', 1],
  ['n', 2],
  ['b', 3],
  ['q', 4],
  ['k', 5],
  ['b', 6],
  ['n', 7],
  ['r', 8],
] as const;

export const genStandardFirstRow = (color: PieceColor): BoardOpp[] => {
  const v = color === 'w' ? 1 : 8;
  return names.map(([name, h]) => ({
    piece: { name, color },
    position: { h, v },
  }));
};

export const genSantardSecondRow = (color: PieceColor): BoardOpp[] => {
  const v = color === 'w' ? 2 : 7;
  return positionIndexes.map((h) => ({
    piece: { name: 'p', color },
    position: { h, v },
  }));
};

export const standardBoard = (): BoardOpp[] => [
  ...genStandardFirstRow('w'),
  ...genStandardFirstRow('b'),
  ...genSantardSecondRow('w'),
  ...genSantardSecondRow('b'),
];

import { Board, isPawn, PieceColor, shift, erase } from '../board';
import { Move, EnPassant } from './typesG';
import { forward, left, backward, right } from './displace';
import { getFromPosition, getToPosition } from './helpers';
import { Position, equalPosition } from '../grid';

const movedTwoRows = (color: PieceColor, previousMove: Move) => {
  const moveTwo = forward(color, getFromPosition(previousMove), 2);
  return !!moveTwo && equalPosition(getToPosition(previousMove), moveTwo);
};

const areRowNeighbours = (colorP1: PieceColor, p1: Position, p2: Position) =>
  p1.v === p2.v &&
  (left(colorP1, p1, 1)?.h === p2.h || right(colorP1, p1, 1)?.h === p2.h);

export const getEnPassantMove = (
  board: Board,
  color: PieceColor,
  from: Position,
  previousMove?: Move
): EnPassant | undefined => {
  if (!previousMove) {
    return;
  }
  const previousMoveTo = getToPosition(previousMove);
  const { piece: movedPawn } = board.read(previousMoveTo);
  if (
    !isPawn(movedPawn) ||
    movedPawn.color === color ||
    !movedTwoRows(movedPawn.color, previousMove) ||
    !areRowNeighbours(color, from, previousMoveTo)
  ) {
    return;
  }
  const enPassantTo = backward(movedPawn.color, previousMoveTo, 1);
  if (!enPassantTo) {
    return;
  }
  return [shift(from, enPassantTo), erase(previousMoveTo)];
};

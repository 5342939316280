import { RowList, Row } from './types';

const genEmptyRow = <Cell>(genEmptyCell: () => Cell): Row<Cell> => [
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
  genEmptyCell(),
];

export const genEmptyRowList = <Cell>(
  genEmptyCell: () => Cell
): RowList<Cell> => [
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
  genEmptyRow(genEmptyCell),
];

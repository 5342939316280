import { BoardOpp } from '../board';
import { genSantardSecondRow, genStandardFirstRow } from './standard';
import { s, w } from '../textNotation';

export const customBoard = (): BoardOpp[] => {
  const base = [
    ...genStandardFirstRow('w'),
    ...genStandardFirstRow('b'),
    ...genSantardSecondRow('b'),
    w('a5wp'),
    w('b2wp'),
    w('c5wp'),
    w('d2wp'),
    w('e5wp'),
    w('f2wp'),
    w('g2wp'),
    w('h2wp'),
    s('d7d6'),
    s('e7e6'),
    s('f7f5'),

    s('b1c5'),
    s('d1f5'),
  ];

  return base.filter((m) => !!m) as BoardOpp[];
};

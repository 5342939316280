/*
 * Generated type guards for "typesG.ts".
 * WARNING: Do not manually change this file.
 */
import { PieceColor, PieceName, Pawn, Rook, Knight, Bishop, Queen, King, Piece, Write, Erase, Shift, BoardOpp, Cell, Row, Grid } from "./typesG";

export function isPieceColor(obj: any, _argumentName?: string): obj is PieceColor {
    return (
        (obj === "w" ||
            obj === "b")
    )
}

export function isPieceName(obj: any, _argumentName?: string): obj is PieceName {
    return (
        (obj === "b" ||
            obj === "q" ||
            obj === "k" ||
            obj === "n" ||
            obj === "r" ||
            obj === "p")
    )
}

export function isPawn(obj: any, _argumentName?: string): obj is Pawn {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "p"
    )
}

export function isRook(obj: any, _argumentName?: string): obj is Rook {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "r"
    )
}

export function isKnight(obj: any, _argumentName?: string): obj is Knight {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "n"
    )
}

export function isBishop(obj: any, _argumentName?: string): obj is Bishop {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "b"
    )
}

export function isQueen(obj: any, _argumentName?: string): obj is Queen {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "q"
    )
}

export function isKing(obj: any, _argumentName?: string): obj is King {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        isPieceColor(obj.color) as boolean &&
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        obj.name === "k"
    )
}

export function isPiece(obj: any, _argumentName?: string): obj is Piece {
    return (
        (isPawn(obj) as boolean ||
            isRook(obj) as boolean ||
            isKnight(obj) as boolean ||
            isBishop(obj) as boolean ||
            isQueen(obj) as boolean ||
            isKing(obj) as boolean)
    )
}

export function isWrite(obj: any, _argumentName?: string): obj is Write {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (obj.position !== null &&
            typeof obj.position === "object" ||
            typeof obj.position === "function") &&
        (obj.position.h === 8 ||
            obj.position.h === 1 ||
            obj.position.h === 2 ||
            obj.position.h === 3 ||
            obj.position.h === 4 ||
            obj.position.h === 5 ||
            obj.position.h === 6 ||
            obj.position.h === 7) &&
        (obj.position.v === 8 ||
            obj.position.v === 1 ||
            obj.position.v === 2 ||
            obj.position.v === 3 ||
            obj.position.v === 4 ||
            obj.position.v === 5 ||
            obj.position.v === 6 ||
            obj.position.v === 7) &&
        isPiece(obj.piece) as boolean
    )
}

export function isErase(obj: any, _argumentName?: string): obj is Erase {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (obj.position !== null &&
            typeof obj.position === "object" ||
            typeof obj.position === "function") &&
        (obj.position.h === 8 ||
            obj.position.h === 1 ||
            obj.position.h === 2 ||
            obj.position.h === 3 ||
            obj.position.h === 4 ||
            obj.position.h === 5 ||
            obj.position.h === 6 ||
            obj.position.h === 7) &&
        (obj.position.v === 8 ||
            obj.position.v === 1 ||
            obj.position.v === 2 ||
            obj.position.v === 3 ||
            obj.position.v === 4 ||
            obj.position.v === 5 ||
            obj.position.v === 6 ||
            obj.position.v === 7)
    )
}

export function isShift(obj: any, _argumentName?: string): obj is Shift {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (obj.from !== null &&
            typeof obj.from === "object" ||
            typeof obj.from === "function") &&
        (obj.from.h === 8 ||
            obj.from.h === 1 ||
            obj.from.h === 2 ||
            obj.from.h === 3 ||
            obj.from.h === 4 ||
            obj.from.h === 5 ||
            obj.from.h === 6 ||
            obj.from.h === 7) &&
        (obj.from.v === 8 ||
            obj.from.v === 1 ||
            obj.from.v === 2 ||
            obj.from.v === 3 ||
            obj.from.v === 4 ||
            obj.from.v === 5 ||
            obj.from.v === 6 ||
            obj.from.v === 7) &&
        (obj.to !== null &&
            typeof obj.to === "object" ||
            typeof obj.to === "function") &&
        (obj.to.h === 8 ||
            obj.to.h === 1 ||
            obj.to.h === 2 ||
            obj.to.h === 3 ||
            obj.to.h === 4 ||
            obj.to.h === 5 ||
            obj.to.h === 6 ||
            obj.to.h === 7) &&
        (obj.to.v === 8 ||
            obj.to.v === 1 ||
            obj.to.v === 2 ||
            obj.to.v === 3 ||
            obj.to.v === 4 ||
            obj.to.v === 5 ||
            obj.to.v === 6 ||
            obj.to.v === 7)
    )
}

export function isBoardOpp(obj: any, _argumentName?: string): obj is BoardOpp {
    return (
        (isWrite(obj) as boolean ||
            isErase(obj) as boolean ||
            isShift(obj) as boolean)
    )
}

export function isCell(obj: any, _argumentName?: string): obj is Cell {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        (typeof obj.piece === "undefined" ||
            isPawn(obj.piece) as boolean ||
            isRook(obj.piece) as boolean ||
            isKnight(obj.piece) as boolean ||
            isBishop(obj.piece) as boolean ||
            isQueen(obj.piece) as boolean ||
            isKing(obj.piece) as boolean)
    )
}

export function isRow(obj: any, _argumentName?: string): obj is Row {
    return (
        Array.isArray(obj) &&
        isCell(obj[0]) as boolean &&
        isCell(obj[1]) as boolean &&
        isCell(obj[2]) as boolean &&
        isCell(obj[3]) as boolean &&
        isCell(obj[4]) as boolean &&
        isCell(obj[5]) as boolean &&
        isCell(obj[6]) as boolean &&
        isCell(obj[7]) as boolean
    )
}

export function isGrid(obj: any, _argumentName?: string): obj is Grid {
    return (
        Array.isArray(obj) &&
        isRow(obj[0]) as boolean &&
        isRow(obj[1]) as boolean &&
        isRow(obj[2]) as boolean &&
        isRow(obj[3]) as boolean &&
        isRow(obj[4]) as boolean &&
        isRow(obj[5]) as boolean &&
        isRow(obj[6]) as boolean &&
        isRow(obj[7]) as boolean
    )
}

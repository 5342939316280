import { Piece as GamePiece, PieceColor } from '../../game';

const pieceWhite = '#A6ACAF';
const pieceBlack = '#424949';
const getFontColor = (c: PieceColor) => (c === 'w' ? pieceWhite : pieceBlack);

const Piece = ({ config }: { config: GamePiece }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      color: getFontColor(config.color),
      fontSize: '70px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      textShadow: '2px 2px #EAECEE',
    }}
  >
    <span>{config.name.toUpperCase()}</span>
  </div>
);

export default Piece;

import { Erase, isPiece, shift, Shift, Write } from './board';
import { Position, PositionIndex } from './grid';

const charToCoord = (char: string): PositionIndex | undefined => {
  switch (char) {
    case 'a':
      return 1;
    case 'b':
      return 2;
    case 'c':
      return 3;
    case 'd':
      return 4;
    case 'e':
      return 5;
    case 'f':
      return 6;
    case 'g':
      return 7;
    case 'h':
      return 8;
  }
};

const strNumToCoord = (strNum: string): PositionIndex | undefined => {
  const int = parseInt(strNum, 10);
  if (isNaN(int) || int > 8 || int < 1) {
    return;
  }
  return int as PositionIndex;
};

export const strToPos = (position: string): Position | undefined => {
  const arr = position.split('');
  if (arr.length !== 2) {
    return;
  }
  const h = charToCoord(arr[0]);
  const v = strNumToCoord(arr[1]);
  return h && v && { h, v };
};

/**
 * Write
 * @returns Write move
 */
export const w = (command: string): Write | Erase | undefined => {
  const arr = command.split('');
  const position = strToPos(arr.slice(0, 2).join(''));
  if (!position || !(arr.length === 4 || arr.length === 2)) {
    return;
  }

  if (arr.length === 4) {
    const piece = { color: arr[2], name: arr[3] };
    return isPiece(piece) ? { position, piece } : undefined;
  }

  return { position };
};

/**
 * Shift
 * @returns Shift move
 */
export const s = (command: string): Shift | undefined => {
  const arr = command.split('');
  const from = strToPos(arr.slice(0, 2).join(''));
  const to = strToPos(arr.slice(2, 4).join(''));
  return from && to && shift(from, to);
};

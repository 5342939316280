import { Position, RowList } from './types';
import { genEmptyRowList } from './init';
import { travel } from './travel';

export type TravelGridCallback<Cell, T> = (
  cell: Cell,
  position: Position
) => T | undefined;

type NormalPositionIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
type NormalPosition = { h: NormalPositionIndex; v: NormalPositionIndex };

const normalize = (p: Position): NormalPosition => ({
  h: (p.h - 1) as NormalPositionIndex,
  v: (p.v - 1) as NormalPositionIndex,
});

export class Grid<Cell> {
  public id = Date.now();
  private rowList: RowList<Cell>;

  constructor(genEmptyCell: () => Cell) {
    this.rowList = genEmptyRowList(genEmptyCell);
  }

  public read = (position: Position): Cell => {
    const { h, v } = normalize(position);
    return this.rowList[v][h];
  };

  public travel = <T>(callback: TravelGridCallback<Cell, T>): T | undefined =>
    travel((position) => callback(this.read(position), position));
}

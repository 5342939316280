import { Promotion, PieceName, isPieceName } from '../../game';

const input = (): PieceName => {
  const pieceName = prompt(
    'Pick a piece: Queen (q), Bishop (b), Knight (n), Rook (r), Pawn (p)'
  )?.toLowerCase();

  if (isPieceName(pieceName)) {
    return pieceName;
  }
  return 'q';
};

export const promote = (promotion: Promotion): Promotion => {
  const shift = promotion[0];

  const name = input();
  const {
    position,
    piece: { color },
  } = promotion[1];
  const newPiece = {
    position,
    piece: { color, name },
  };

  return [shift, newPiece];
};

import { Piece as GamePiece } from '../../game';
import { default as TextPiece } from './text';
import { default as defaultPieceSet } from './default';
import { default as plasticPieceSet } from './plastic';

type ImagePieceSetColor = {
  r: string;
  n: string;
  b: string;
  q: string;
  k: string;
  p: string;
};

type ImagePieceSet = {
  w: ImagePieceSetColor;
  b: ImagePieceSetColor;
};

export type PieceType = 'default' | 'plastic' | 'text';

export type PieceProps = {
  config: GamePiece;
  type?: PieceType;
};

type ImagePieceProps = {
  config: GamePiece;
  pieceSet: ImagePieceSet;
};

const ImagePiece = ({ config, pieceSet }: ImagePieceProps) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={pieceSet[config.color][config.name]}
        alt={`${config.color}${config.name}`}
        style={{
          maxWidth: '80%',
          maxHeight: '80%',
        }}
      />
    </div>
  );
};

export const Piece = ({ config, type }: PieceProps) => {
  switch (type) {
    case 'text':
      return <TextPiece config={config} />;
    case 'plastic':
      return <ImagePiece config={config} pieceSet={plasticPieceSet} />;
    default:
      return <ImagePiece config={config} pieceSet={defaultPieceSet} />;
  }
};

import { equalErase, equalShift, equalWrite, isShift } from '../board';
import { Position } from '../grid';
import { Move } from './typesG';
import {
  isCastling,
  isEnPassant,
  isPromotion,
  isSpecialMove,
} from './typesG.guard';

export const getToPosition = (move: Move): Position =>
  isSpecialMove(move) ? move[0].to : move.to;

export const getFromPosition = (move: Move): Position =>
  isSpecialMove(move) ? move[0].from : move.from;

export const getToPositions = (moves: Move[]) => moves.map(getToPosition);

const equalCastling = (moveA: Move, moveB: Move) =>
  isCastling(moveA) &&
  isCastling(moveB) &&
  equalShift(moveA[0], moveB[0]) &&
  equalShift(moveA[1], moveB[1]);

const equalEnPassant = (moveA: Move, moveB: Move) =>
  isEnPassant(moveA) &&
  isEnPassant(moveB) &&
  equalShift(moveA[0], moveB[0]) &&
  equalErase(moveA[1], moveB[1]);

const equalPromotion = (moveA: Move, moveB: Move) =>
  isPromotion(moveA) &&
  isPromotion(moveB) &&
  equalShift(moveA[0], moveB[0]) &&
  equalWrite(moveA[1], moveB[1]);

export const equalMove = (moveA: Move, moveB: Move) =>
  (isShift(moveA) && isShift(moveB) && equalShift(moveA, moveB)) ||
  equalEnPassant(moveA, moveB) ||
  equalCastling(moveA, moveB) ||
  equalPromotion(moveA, moveB);

/*
 * Generated type guards for "typesG.ts".
 * WARNING: Do not manually change this file.
 */
import { isShift, isErase, isWrite } from "../board/typesG.guard";
import { EnPassant, Promotion, Castling, SpecialMove, Move, Cell } from "./typesG";

export function isEnPassant(obj: any, _argumentName?: string): obj is EnPassant {
    return (
        Array.isArray(obj) &&
        isShift(obj[0]) as boolean &&
        isErase(obj[1]) as boolean
    )
}

export function isPromotion(obj: any, _argumentName?: string): obj is Promotion {
    return (
        Array.isArray(obj) &&
        isShift(obj[0]) as boolean &&
        isWrite(obj[1]) as boolean
    )
}

export function isCastling(obj: any, _argumentName?: string): obj is Castling {
    return (
        Array.isArray(obj) &&
        isShift(obj[0]) as boolean &&
        isShift(obj[1]) as boolean
    )
}

export function isSpecialMove(obj: any, _argumentName?: string): obj is SpecialMove {
    return (
        (isEnPassant(obj) as boolean ||
            isPromotion(obj) as boolean ||
            isCastling(obj) as boolean)
    )
}

export function isMove(obj: any, _argumentName?: string): obj is Move {
    return (
        (isEnPassant(obj) as boolean ||
            isPromotion(obj) as boolean ||
            isCastling(obj) as boolean ||
            isShift(obj) as boolean)
    )
}

export function isCell(obj: any, _argumentName?: string): obj is Cell {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        Array.isArray(obj.incoming) &&
        obj.incoming.every((e: any) =>
            isMove(e) as boolean
        ) &&
        Array.isArray(obj.outgoing) &&
        obj.outgoing.every((e: any) =>
            isMove(e) as boolean
        )
    )
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Game,
  Position,
  Move,
  getToPosition,
  getFromPosition,
  isPromotion,
  getToPositions,
  Piece,
  equalPosition,
} from '../../game';
import { s, strToPos } from '../../game/textNotation';

import { HighlightType } from '../cell';
import { promote } from './promote';

const getMoveTo = (
  cellPosition: Position,
  possibleMoves: Move[]
): Move | undefined =>
  possibleMoves.find((move) =>
    equalPosition(getToPosition(move), cellPosition)
  );

const shouldHighLight = (highligth: Position[], position: Position): boolean =>
  !!highligth.find((p) => p.h === position.h && p.v === position.v);

const isPreviousMoveCell = (cellPosition: Position, previousMove?: Move) =>
  previousMove &&
  (equalPosition(getFromPosition(previousMove), cellPosition) ||
    equalPosition(getToPosition(previousMove), cellPosition));

const getHighlightType = (
  game: Game,
  highLight: Position[],
  cellPosition: Position,
  wasSelected: boolean
): HighlightType | undefined =>
  shouldHighLight(highLight, cellPosition)
    ? 'selected'
    : !wasSelected && isPreviousMoveCell(cellPosition, game.previousMove)
    ? 'moved'
    : undefined;

const getPossibleMoves = (read: Game['read'], position?: Position) =>
  position ? read(position).possibleMoves || [] : [];

type UseGameControllerArgs = {
  game: Game;
  highlightPossibleMoves?: boolean;
  playerColor?: 'w' | 'b';
  command?: string;
};

export const useBoardController = ({
  game,
  playerColor,
  highlightPossibleMoves,
  command,
}: UseGameControllerArgs) => {
  const [selected, setSelected] = useState<Position | undefined>();
  const [wasSelected, setWasSelected] = useState<boolean>(false);
  const [highLight, setHighlight] = useState<Position[]>([]);

  const possibleMoves = useMemo(
    () => getPossibleMoves(game.read, selected),
    [game.read, selected]
  );

  useEffect(() => {
    if (!!selected) {
      !!highlightPossibleMoves && selected
        ? setHighlight([...getToPositions(possibleMoves), selected])
        : setHighlight([selected]);
    } else {
      setHighlight([]);
    }
  }, [game, selected, possibleMoves, highlightPossibleMoves]);

  useEffect(() => {
    setHighlight([]);
  }, [game]);

  // should not select if is capturable by selected and it's not by turns
  const canSelect = useCallback(
    (piece?: Piece) =>
      !!piece &&
      game.isColorTurn(piece.color) &&
      (!playerColor || playerColor === piece.color),
    [game, playerColor]
  );

  useEffect(() => {
    if (command) {
      const p = strToPos(command);
      if (p) {
        const { piece } = game.read(p);
        if (canSelect(piece)) {
          setSelected(p);
          setWasSelected(true);
        }
      }
      const m = s(command);
      if (m) {
        game.move(m);
        setWasSelected(false);
        setSelected(undefined);
      }
    }
  }, [command, setSelected, setWasSelected, game, canSelect]);

  (window as any)['select'] = (command: string) => {};

  const getCellConfig = (cellPosition: Position) => {
    const { piece } = game.read(cellPosition);
    return {
      piece,
      highlightType: getHighlightType(
        game,
        highLight,
        cellPosition,
        wasSelected
      ),
      onClick: () => {
        const moveTo = getMoveTo(cellPosition, possibleMoves);
        if (!!selected && equalPosition(selected, cellPosition)) {
          setSelected(undefined);
        } else if (moveTo) {
          if (isPromotion(moveTo)) {
            game.move(promote(moveTo));
          } else {
            game.move(moveTo);
          }
          setWasSelected(false);
          setSelected(undefined);
        } else if (canSelect(piece)) {
          setSelected(cellPosition);
          setWasSelected(true);
        }
      },
    };
  };

  return { getCellConfig };
};

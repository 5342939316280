import { Grid, Position } from '../grid';
import { Board, isKing, PieceColor } from '../board';
import { Cell, Move } from './typesG';
import { getCastlingPossibleMoves, WasMoved } from './castling';
import { generatePosition } from './generateMoves';
import { getToPosition } from './helpers';

export type KingsPositions = { w?: Position; b?: Position };

export type GenerateOptions = {
  previousMove?: Move;
  wasMoved?: WasMoved;
  includeCheckMoves?: boolean; // To help filtering check moves away
};

export class PossibleMoves extends Grid<Cell> {
  private kingsPositions: KingsPositions = {};

  constructor(private board: Board, opt?: GenerateOptions) {
    super((): Cell => ({ incoming: [], outgoing: [] }));
    this.generate(this.board, opt);
  }

  public getKingsPositions = () => this.kingsPositions;

  public isCheck = (color: PieceColor) => {
    const kingPostion = this.kingsPositions[color];
    return !!kingPostion && this.read(kingPostion).incoming.length > 0;
  };

  public isCheckmate = (color: PieceColor) =>
    !this.travel(({ outgoing }, position) => {
      const { piece } = this.board.read(position);
      if (piece && piece?.color === color) {
        return outgoing.length > 0;
      }
    });

  private clearIncomingMoves = () => {
    this.travel((cell) => {
      cell.incoming = [];
    });
  };

  private attachPossibleMoves = (position: Position, possibleMoves: Move[]) => {
    const outgoingCell = this.read(position);
    outgoingCell.outgoing = [...outgoingCell.outgoing, ...possibleMoves];

    possibleMoves.forEach((move) => {
      const moveTo = getToPosition(move);
      const incomingCell = this.read(moveTo);
      incomingCell.incoming.push(move);
    });
  };

  private setPossibleMoves = (position: Position, possibleMoves: Move[]) => {
    const outgoingCell = this.read(position);
    outgoingCell.outgoing = possibleMoves;

    possibleMoves.forEach((move) => {
      const moveTo = getToPosition(move);
      const incomingCell = this.read(moveTo);
      incomingCell.incoming.push(move);
    });
  };

  private filterLeadToCheckMoves = (board: Board, opt?: GenerateOptions) => {
    this.clearIncomingMoves();
    board.travel(({ piece }, position) => {
      if (!piece) {
        return;
      }
      const outgoingMoves = this.read(position).outgoing;
      const filteredMoves = outgoingMoves.filter((move) => {
        const clonedBoard = board.clone();
        clonedBoard.runOpps(move);
        const clonedPossibleMoves = new PossibleMoves(clonedBoard, {
          ...opt,
          includeCheckMoves: true,
        });
        return !clonedPossibleMoves.isCheck(piece.color);
      });
      this.setPossibleMoves(position, filteredMoves);
    });
  };

  private generate = (board: Board, opt: GenerateOptions = {}) => {
    const { previousMove, includeCheckMoves, wasMoved } = opt;
    // All basic moves
    board.travel(({ piece }, position) => {
      if (piece) {
        const possibleMoves: Move[] = generatePosition(
          board,
          position,
          previousMove
        );

        this.setPossibleMoves(position, possibleMoves);

        if (isKing(piece)) {
          this.kingsPositions[piece.color] = position;
        }
      }
    });

    // Add Castling if is not check
    (['w', 'b'] as const).forEach((kingColor) => {
      if (!this.isCheck(kingColor)) {
        const castling = getCastlingPossibleMoves(board, kingColor, wasMoved);
        const kingPosition = this.kingsPositions[kingColor];
        kingPosition && this.attachPossibleMoves(kingPosition, castling);
      }
    });

    // Remove moves that lead to a check
    if (!includeCheckMoves) {
      this.filterLeadToCheckMoves(board, opt);
    }
  };
}

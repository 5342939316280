import React from 'react';
import { getTheme } from './theme';

const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: getTheme().colors.page.background,
};

export const Container = ({ children }: { children: React.ReactNode }) => (
  <div style={containerStyles}>{children}</div>
);

const fameMargin = 10;

const frameCssSize = (size: number) =>
  `calc(min(${size}vw,${size}vh) - ${fameMargin * 2}px)`;

type FrameProps = { size: number; children: React.ReactNode };

export const Frame = ({ size, children }: FrameProps) => (
  <div
    style={{
      overflow: 'hidden',
      background: 'orange',
      width: frameCssSize(size),
      height: frameCssSize(size),
      margin: fameMargin,
      border: '1px solid grey',
      borderRadius: '5px',
      userSelect: 'none',
    }}
  >
    {children}
  </div>
);

export const Row = ({ children }: { children: React.ReactNode }) => (
  <div style={{ height: '12.5%' }}>{children}</div>
);

import { Grid } from '../grid';
import { Cell, BoardOpp, Write, Shift, Erase } from './typesG';
import { isWrite, isShift, isErase } from './typesG.guard';
import { write } from './helpers';

export class Board extends Grid<Cell> {
  constructor(init: BoardOpp[]) {
    super((): Cell => ({}));
    this.runOpps(init);
  }

  private write = (opp: Write | Erase) => {
    const { position, piece } = { piece: undefined, ...opp };
    this.read(position).piece = piece;
  };

  private shift = ({ from, to }: Shift) => {
    const { piece } = this.read(from);
    this.write({ piece, position: to });
    this.write({ position: from });
  };

  private runOpp = (opp: BoardOpp) => {
    if (isWrite(opp) || isErase(opp)) {
      this.write(opp);
    } else if (isShift(opp)) {
      this.shift(opp);
    }
  };

  public runOpps = (opp: BoardOpp | BoardOpp[]) =>
    opp instanceof Array ? opp.forEach(this.runOpp) : this.runOpp(opp);

  public clone = (): Board => {
    const cloned = new Board([]);
    this.travel(({ piece }, position) => {
      if (piece) {
        const clonedPiece = { ...piece };
        cloned.runOpps(write(position, clonedPiece));
      }
    });
    return cloned;
  };
}

/* 

https://pixabay.com/illustrations/chess-black-and-white-pieces-3413429/

*/

import bb from './bb.png';
import bk from './bk.png';
import bn from './bn.png';
import bp from './bp.png';
import bq from './bq.png';
import br from './br.png';

import wb from './wb.png';
import wk from './wk.png';
import wn from './wn.png';
import wp from './wp.png';
import wq from './wq.png';
import wr from './wr.png';

const pieceSet = {
  b: {
    r: br,
    n: bn,
    b: bb,
    q: bq,
    k: bk,
    p: bp,
  },
  w: {
    r: wr,
    n: wn,
    b: wb,
    q: wq,
    k: wk,
    p: wp,
  },
};

export default pieceSet;

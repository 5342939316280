import { BoardOpp, PieceColor, PieceName } from '../board';
import { PositionIndex } from '../grid';

function randPosition(): PositionIndex {
  const min = 1;
  const max = 8;
  return Math.floor(Math.random() * (max - min + 1) + min) as PositionIndex;
}

const pieces: PieceName[] = ['r', 'n', 'b', 'q', 'k', 'b', 'n', 'r'];

export const randomBoard = (): BoardOpp[] => {
  const list: BoardOpp[] = [];
  const genColor = (color: PieceColor) =>
    pieces.forEach((name) => {
      let x: PositionIndex = 1;
      let y: PositionIndex = 1;
      let exists = true;
      do {
        x = randPosition();
        y = randPosition();
        // exists = !!list.find(({ position: { h, v } }) => h === x && v === y);
      } while (!exists);
      list.push({ piece: { name, color }, position: { h: x, v: y } });
    });
  genColor('w');
  genColor('b');
  return list;
};

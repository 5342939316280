import { PieceColor } from '../board';
import { Position, PositionIndex } from '../grid';

export const isFinalRow = (color: PieceColor, p: Position) => {
  return (color === 'w' && p.v === 8) || (color === 'b' && p.v === 1);
};

export const forward = (
  color: PieceColor,
  p: Position,
  slots: number
): Position | undefined => {
  let newPos: number;
  if (color === 'w') {
    newPos = p.v + slots;
  } else {
    newPos = p.v - slots;
  }
  if (newPos < 1 || newPos > 8) {
    return;
  }
  return { ...p, v: newPos as PositionIndex };
};

export const backward = (color: PieceColor, p: Position, slots: number) =>
  forward(color, p, slots * -1);

export const left = (
  color: PieceColor,
  p: Position,
  slots: number
): Position | undefined => {
  let newPos: number;
  if (color === 'w') {
    newPos = p.h - slots;
  } else {
    newPos = p.h + slots;
  }
  if (newPos < 1 || newPos > 8) {
    return;
  }
  return { ...p, h: newPos as PositionIndex };
};

export const right = (color: PieceColor, p: Position, slots: number) =>
  left(color, p, slots * -1);

export const forwardLeft = (
  color: PieceColor,
  p: Position,
  slotsForward: number,
  slotsLeft: number
): Position | undefined => {
  const leftPos = left(color, p, slotsLeft);
  if (!leftPos) {
    return;
  }
  return forward(color, leftPos, slotsForward);
};

import { getTheme } from './theme';
// import { createUseStyles } from 'react-jss';

// const bodyHeight = document.documentElement.scrollHeight;
import { Piece as GamePiece, Position } from '../game';
import { Piece } from './piece';

type CellColor = 'w' | 'b';

const printCoords = ({ h, v }: Position) =>
  `${String.fromCharCode(h + 96)}${v}`.toUpperCase();

const getCellColor = ({ h, v }: Position): CellColor =>
  (h + v) % 2 === 0 ? 'b' : 'w';

const getBgColor = (position: Position) =>
  getCellColor(position) === `w`
    ? getTheme().colors.cell.white
    : getTheme().colors.cell.black;

export type HighlightType = 'selected' | 'moved';
const getHighlightColor = (highlightType: HighlightType) =>
  getTheme().colors.cell[highlightType];

export type CellProps = {
  position: Position;
  displayCoords?: boolean;
  piece?: GamePiece;
  onClick: () => void;
  highlight?: HighlightType;
};

export const Cell = ({
  position,
  piece,
  onClick,
  highlight,
  displayCoords,
}: CellProps) => {
  const bgColor = getBgColor(position);

  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        width: '12.5%',
        backgroundColor: bgColor,
        border: `2px solid ${
          highlight ? getHighlightColor(highlight) : bgColor
        }`,
      }}
      onClick={onClick}
    >
      {displayCoords ? (
        <div
          style={{
            position: 'absolute',
            left: '2px',
            bottom: '2px',
            color: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          {printCoords(position)}
        </div>
      ) : null}
      {piece ? <Piece config={piece} type={getTheme().pieceType} /> : null}
    </div>
  );
};

import { useEffect } from 'react';
import { Cell } from './cell';
import { Game, PositionIndex } from '../game';
import { Frame, Container, Row } from './layout';
import { useBoardController } from './boardController';

export type Rotation = 0 | 90 | 180 | 270;

const asc: Array<PositionIndex> = [1, 2, 3, 4, 5, 6, 7, 8];
const desc: Array<PositionIndex> = [...asc].reverse();

const notifyCheckMate = ({ w, b }: Game['checkmate']) => {
  if (w) {
    alert('checkmate, mate. BLACK WINS');
  }
  if (b) {
    alert('checkmate, mate. WHITE WINS');
  }
};

const Layout = ({
  rotation,
  children,
}: {
  rotation: Rotation;
  children: (props: { h: PositionIndex; v: PositionIndex }) => React.ReactNode;
}) => {
  const vertical = rotation === 0 || rotation === 270 ? desc : asc;
  const horizontal = rotation === 180 || rotation === 270 ? desc : asc;
  return (
    <>
      {vertical.map((v) => (
        <Row key={`row-${v}`}>
          {horizontal.map((h) => {
            const position =
              rotation === 0 || rotation === 180 ? { h, v } : { h: v, v: h };
            return children(position);
          })}
        </Row>
      ))}
    </>
  );
};

export type BoardProps = {
  game: Game;
  highlightPossibleMoves?: boolean;
  displayCellName?: boolean;
  playerColor?: 'w' | 'b';
  size: number;
  rotation?: Rotation;
  command?: string;
};

export const Board = ({
  game,
  playerColor,
  highlightPossibleMoves,
  displayCellName,
  size,
  rotation = 0,
  command,
}: BoardProps) => {
  const { getCellConfig } = useBoardController({
    game,
    highlightPossibleMoves,
    playerColor,
    command,
  });

  useEffect(() => {
    notifyCheckMate(game.checkmate);
  }, [game.checkmate]);

  return (
    <Container>
      <Frame size={size}>
        <Layout rotation={rotation}>
          {(pos) => {
            const key = `cell-${pos.v}${pos.h}`;
            const { highlightType, piece, onClick } = getCellConfig(pos);

            return (
              <Cell
                key={key}
                position={pos}
                displayCoords={displayCellName}
                piece={piece}
                highlight={highlightType}
                onClick={onClick}
              />
            );
          }}
        </Layout>
      </Frame>
    </Container>
  );
};

import { equalPosition, Position } from '../grid';
import { Piece, Shift, Write, Erase } from './typesG';

export const erase = (position: Position): Erase => ({ position });

export const write = (position: Position, piece: Piece): Write => ({
  position,
  piece,
});

export const shift = (from: Position, to: Position): Shift => ({
  from,
  to,
});

export const equalPiece = (pA?: Piece, pB?: Piece) =>
  (!pA && !pB) || (pA?.color === pB?.color && pA?.name === pB?.name);

export const equalWrite = (wA: Write, wB: Write) =>
  equalPosition(wA.position, wB.position) && equalPiece(wA.piece, wB.piece);

export const equalErase = (wA: Erase, wB: Erase) =>
  equalPosition(wA.position, wB.position);

export const equalShift = (sA: Shift, sB: Shift) =>
  equalPosition(sA.from, sB.from) && equalPosition(sA.to, sB.to);

import { customBoard } from './custom';
import { randomBoard } from './random';
import { standardBoard } from './standard';
import { BoardOpp } from '../board';

export type BoardType = 'standard' | 'empty' | 'random' | 'custom';

export const generateBoard = (type: BoardType = 'standard'): BoardOpp[] => {
  switch (type) {
    case 'standard':
      return standardBoard();
    case 'random':
      return randomBoard();
    case 'custom':
      return customBoard();
    default:
      return [];
  }
};

import { useState } from 'react';
import { Board, Rotation } from '../UI';
import { useGame } from '../game';

const byTurns = true;
const playerColor = undefined;
const boardType = 'standard';
const highlightPossibleMoves = true;
const displayCellName = true;

export const Play = () => {
  const [rotation, setRotation] = useState<Rotation>(0);
  const [command, setCommand] = useState<string>();
  const game = useGame({ byTurns, boardType });

  (window as any)['rotate'] = () => {
    setRotation(((rotation + 90) % 360) as Rotation);
  };

  (window as any)['cmd'] = (cmd: string) => {
    setCommand(cmd);
  };

  return (
    <Board
      size={90}
      game={game}
      playerColor={playerColor}
      highlightPossibleMoves={highlightPossibleMoves}
      displayCellName={displayCellName}
      rotation={rotation}
      command={command}
    />
  );
};

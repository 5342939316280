import { PieceType } from './piece';

const theme = {
  pieceType: 'default' as const,
  colors: {
    page: {
      // background: '#2D2D2D',
      background: '#3D3D3D',
    },
    cell: {
      selected: '#85C1E9',
      black: '#BFC9CA',
      white: '#E5E8E8',
      moved: '#F7DC6F',
    },
  },
};

export type Theme = typeof theme & { pieceType: PieceType };

export const getTheme = (): Theme => theme;

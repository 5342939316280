import { Position } from '../grid';
import { Board, isKing, isRook, PieceColor, shift } from '../board';
import { Castling, Move } from './typesG';
import { left, right } from './displace';
import { getFromPosition } from './helpers';
import { equalPosition } from '../grid';

type CastlingType = 'king' | 'queen';

type WasMovedColor = {
  king: boolean;
  queenRook: boolean;
  kingRook: boolean;
};

export type WasMoved = {
  w: WasMovedColor;
  b: WasMovedColor;
};

const getKingPosition = (color: PieceColor): Position => {
  const v = color === 'w' ? 1 : 8;
  return { h: 5, v };
};

const getRookPosition = (type: CastlingType, color: PieceColor): Position => {
  const v = color === 'w' ? 1 : 8;
  const h = type === 'king' ? 8 : 1;
  return { h, v };
};

const getCastlingMove = (type: CastlingType, color: PieceColor): Castling => {
  const v = color === 'w' ? 1 : 8;
  const kingToPosition: Position = type === 'king' ? { h: 7, v } : { h: 3, v };
  const kingShift = shift(getKingPosition(color), kingToPosition);
  const rookFromPosition: Position = getRookPosition(type, color);
  const rookToPosition: Position = type === 'king' ? { h: 6, v } : { h: 4, v };
  const rookShift = shift(rookFromPosition, rookToPosition);
  return [kingShift, rookShift];
};

const getCastlingPath = (kingPosition: Position, type: CastlingType) =>
  type === 'king'
    ? [right('w', kingPosition, 1), right('w', kingPosition, 2)]
    : [
        left('w', kingPosition, 1),
        left('w', kingPosition, 2),
        left('w', kingPosition, 3),
      ];

const isCastlingBlocked = (
  board: Board,
  type: CastlingType,
  color: PieceColor
) => {
  const kingPosition = getKingPosition(color);
  return getCastlingPath(kingPosition, type).some(
    (pos) => !!pos && !!board.read(pos).piece
  );
};

const arePiecesInPosition = (
  board: Board,
  type: CastlingType,
  color: PieceColor
) => {
  const isKingInPosition = isKing(board.read(getKingPosition(color)).piece);
  return (
    isKingInPosition && isRook(board.read(getRookPosition(type, color)).piece)
  );
};

const getCastlingPossibleType = (
  board: Board,
  type: CastlingType,
  color: PieceColor,
  wasMoved: WasMoved
): Castling | undefined => {
  const wasRookMoved =
    type === 'king' ? wasMoved[color].kingRook : wasMoved[color].queenRook;
  if (!wasMoved[color].king && !wasRookMoved) {
    if (
      arePiecesInPosition(board, type, color) &&
      !isCastlingBlocked(board, type, color)
    ) {
      return getCastlingMove(type, color);
    }
  }
};

export const getCastlingPossibleMoves = (
  board: Board,
  color: PieceColor,
  wasMoved?: WasMoved
): Castling[] => {
  if (!wasMoved) {
    return [];
  }
  const r: Castling[] = [];
  (['king', 'queen'] as const).forEach((type) => {
    const move = getCastlingPossibleType(board, type, color, wasMoved);
    !!move && r.push(move);
  });
  return r;
};

export const checkWasMoved = ({ w, b }: WasMoved, move: Move): WasMoved => {
  const newWasMoved = { w: { ...w }, b: { ...b } };
  (['w', 'b'] as const).forEach((color) => {
    const fromPosition = getFromPosition(move);
    if (equalPosition(fromPosition, getKingPosition(color))) {
      newWasMoved[color].king = true;
    }
    if (equalPosition(fromPosition, getRookPosition('king', color))) {
      newWasMoved[color].kingRook = true;
    }
    if (equalPosition(fromPosition, getRookPosition('queen', color))) {
      newWasMoved[color].queenRook = true;
    }
  });
  return newWasMoved;
};

import {
  Board,
  PieceColor,
  isPawn,
  isKnight,
  isKing,
  isQueen,
  isRook,
  isBishop,
  isShift,
  shift,
  write,
} from '../board';
import { Move } from './typesG';
import { forward, forwardLeft, isFinalRow } from './displace';
import { getEnPassantMove } from './enPassant';
import { Position } from '../grid';

type MovesGenerator = (
  board: Board,
  color: PieceColor,
  fromPosition: Position
) => Move[];

const pawn = (
  board: Board,
  color: PieceColor,
  fromPosition: Position,
  previousMove?: Move
): Move[] => {
  const preMoves: Move[] = [];

  // Move 1 slot
  const forward1 = forward(color, fromPosition, 1);
  const canForward1 = forward1 && !board.read(forward1).piece;

  if (canForward1) {
    preMoves.push(shift(fromPosition, forward1));
  }

  // Capture
  [1, -1].forEach((slots) => {
    const capture = forwardLeft(color, fromPosition, 1, slots);
    const victim = !!capture && board.read(capture).piece;
    if (!!victim && victim.color !== color) {
      preMoves.push(shift(fromPosition, capture));
    }
  });

  // Promotions
  const moves = preMoves.map((move): Move => {
    if (isShift(move) && isFinalRow(color, move.to)) {
      return [move, write(move.to, { color, name: 'q' })];
    }
    return move;
  });

  // Move 2 slots
  const couldForward2 =
    (color === 'w' && fromPosition.v === 2) ||
    (color === 'b' && fromPosition.v === 7);
  const forward2 = forward(color, fromPosition, 2);
  const canForward2 =
    !!canForward1 &&
    !!couldForward2 &&
    !!forward2 &&
    !board.read(forward2).piece;

  if (canForward2) {
    moves.push(shift(fromPosition, forward2));
  }

  const enPassant =
    previousMove && getEnPassantMove(board, color, fromPosition, previousMove);
  enPassant && moves.push(enPassant);

  return moves;
};

const knight: MovesGenerator = (board, color, fromPosition) => {
  const result: Move[] = [];
  [
    [2, 1],
    [-2, 1],
    [2, -1],
    [-2, -1],
    [1, 2],
    [1, -2],
    [-1, 2],
    [-1, -2],
  ].forEach(([f, s]) => {
    const toPosition = forwardLeft(color, fromPosition, f, s);
    const victim = toPosition && board.read(toPosition).piece;
    if (toPosition && victim?.color !== color) {
      result.push(shift(fromPosition, toPosition));
    }
  });

  return result;
};

const king: MovesGenerator = (board, color, fromPosition) => {
  const result: Move[] = [];
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      if (i === 0 && j === 0) {
        continue;
      }
      const toPosition = forwardLeft(color, fromPosition, i, j);
      const victim = toPosition && board.read(toPosition).piece;
      if (toPosition && (!victim || victim.color !== color)) {
        result.push(shift(fromPosition, toPosition));
      }
    }
  }
  return result;
};

const progress = (
  board: Board,
  color: PieceColor,
  pos: Position,
  forwardDirection: 0 | 1 | -1,
  leftDirection: 0 | 1 | -1
) => {
  const result: Position[] = [];

  let newPos: Position | undefined;
  let doneVictim = false;
  let doneAlli = false;
  let slotL = 1;
  let slotF = 1;
  do {
    newPos = forwardLeft(
      color,
      pos,
      slotF * forwardDirection,
      slotL * leftDirection
    );
    const victim = newPos && board.read(newPos).piece;
    if (newPos) {
      doneVictim = !!victim && victim.color !== color;
      doneAlli = !!victim && victim.color === color;
      if (!victim || doneVictim) {
        result.push(newPos);
      }
    }
    slotL++;
    slotF++;
  } while (!!newPos && !doneVictim && !doneAlli);

  return result;
};

type PieceDirectionUnit = -1 | 0 | 1;
type PieceDirection = [PieceDirectionUnit, PieceDirectionUnit];
type PieceDirections = PieceDirection[];

const rookDirections: PieceDirections = [
  [-1, 0],
  [0, -1],
  [0, 1],
  [1, 0],
];

const bishopDirections: PieceDirections = [
  [-1, -1],
  [-1, 1],
  [1, -1],
  [1, 1],
];

const queenDirections = [...rookDirections, ...bishopDirections];

const progressDirections = (
  board: Board,
  color: PieceColor,
  fromPosition: Position,
  directions: PieceDirections
) => {
  const toPositions = directions.reduce(
    (r, [f, l]) => [...r, ...progress(board, color, fromPosition, f, l)],
    [] as Position[]
  );
  return toPositions.map((toPosition) => shift(fromPosition, toPosition));
};

const queen: MovesGenerator = (board, color, fromPosition) =>
  progressDirections(board, color, fromPosition, queenDirections);

const rook: MovesGenerator = (board, color, fromPosition) =>
  progressDirections(board, color, fromPosition, rookDirections);

const bishop: MovesGenerator = (board, color, fromPosition) =>
  progressDirections(board, color, fromPosition, bishopDirections);

export const generatePosition = (
  board: Board,
  from: Position,
  previousMove?: Move
): Move[] => {
  const { piece } = board.read(from);
  return (
    (isPawn(piece) && pawn(board, piece.color, from, previousMove)) ||
    (isKnight(piece) && knight(board, piece.color, from)) ||
    (isKing(piece) && king(board, piece.color, from)) ||
    (isQueen(piece) && queen(board, piece.color, from)) ||
    (isRook(piece) && rook(board, piece.color, from)) ||
    (isBishop(piece) && bishop(board, piece.color, from)) ||
    []
  );
};
